// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { IEnvironment } from './environment.interface';

export const environment : IEnvironment = {
    name: 'toro-dev',
    production: false,
    showApiEndpointInFooter: true,
    appVersion: require('../../package.json').config.footerVersion,
    i18nCacheBuster: `-${require('../../package.json').config.i18nCacheBuster}`,

    isDemoMode: false,                  // Sets weather or not static demo mode is enabled. Site will ONLY be in Demo Mode.
    allowDynamicDemoMode: true,        // Sets weather or not dynamic or static demo mode is allowed. /demo path will display Demo Mode.

    useMockNsnData: false,

    dashSiteUrl: 'https://intellidash-dev.torodev.com',
    dashApiUrl: 'https://intellidashapi-dev.torodev.com',
    // assetTrackingSiteUrl: 'https://tracking-dev.torodev.com',
    // assetTrackingApiUrl: 'https://trackingapi-dev.torodev.com',
    assetTrackingSiteUrl: 'https://tracking-beta.torodev.com',
    assetTrackingApiUrl: 'https://trackingapi-beta.torodev.com',


    // Stage Ping
    // toroSsoServerUrl: 'https://stage-identity.toro.com',
    // clientId: 'StageLynxDashClient',
    // clientSecret: 'LxzDVuopmDBsq5SzJ0OzPgdmEXVBrAEg3ru6sf1qrJXGef5UaaUgYpK2jTiXNsFM',
    toroSsoServerUrl: 'https://identity.toro.com',                                          // Prod Ping
    clientId: 'LynxDashClient',                                                             // Prod Ping
    clientSecret: 'UZ93gybIGxlKjQGc3rphOdWsOKsilVUtdYE8ih9ZrB5yo5SsgouiRrJbbG19mcEN',       // Prod Ping

    hideWidgetAlertBanner: false,
    widgetDataUpdateIntervalInMinutes: 1,
    widgetDataStaleThresholdInMinutes: 15,

    weatherRadarOverlayUrl: 'https://tile.irrigationweatherservice.com/api/map/radar',
    weatherRadarOverlayApiKey: 'U2Z5BpeHxQ9i3F9nz7Mm1yVMxZaysgG8fw6Jb6Ec',

    // Dev myTurf
    myTurfUrl: 'https://dev-myturf2.toro.com',
    myTurfAuthUri: 'https://ttcmyturfb2cdevtest.b2clogin.com/ttcmyturfb2cdevtest.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_MyTurfSignUpOrIn',
    myTurfClientId: 'f84d64b3-1987-4697-92f9-3e43aeb54ac0',

    // Stage Nsn for Widget links
    // nsnUrl: 'https://stage-my.toronsn.com',
    nsnUrl: 'https://my.toronsn.com',               // Prod - Must align with identity provider.

    taskTrackerUrl: 'https://www.advancedscoreboard.com/tracker/',
    playbooksBaseLinkUrl: 'https://www.covsys.net',
    perryWeatherUrl: 'https://login.pocketperry.com/Account/Login?ReturnUrl=%2fconnect%2fauthorize%2fcallback%3Fclient_id%3Dpw3-a%26redirect_uri%3Dhttps%253A%252F%252Fapp.perryweather.com%252Fcallback%26response_type%3Dcode%26scope%3Dopenid%2520profile%2520api1%2520email%2520phone%2520offline_access%2520app_access%2520widget-api%2520WIDGET_ADMIN%26state%3D5a0f2c361ef64d7688e9995379c42253%26code_challenge%3DmdKR-4pnj-vEnDiqAcXacG2YGadgBngA_07UjV793DA%26code_challenge_method%3DS256%26response_mode%3Dquery',
    gcsaaUrl: 'https://www.gcsaa.org/',

    pogoSiteUrl: 'https://pogoturfpro.com/',
    pogoApiUrl: 'https://api.pogoturfpro.com/api/partner/intellidash',

    soilScoutHubUrl: 'http://soilscouts.fi/',

    ezLocatorLinkUrl: 'https://www.pinsheet.com',
    ezLocatorApiKey: '8fea25de-0f83-40c7-afd2-27f424f005e5',

    spectrumApiUrl: 'https://api.specconnect.net:6703/api',
    spectrumSiteUrl: 'https://www.specmeters.com/my-account/login/',
    spectrumWizardUrl: 'https://www.specmeters.com/shop/soilmonitoring/soilmonitoring-TDR',

    clearAgApiUrl: 'https://ag.us.clearapis.com/v1.1',
    clearAgAppId: 'feccba4f',
    clearAgAppKey: '9dfc769d5e45ff6820432b738c01b70b',

    lynxCloudUrl: 'https://lynxcloud.toro.com/#/login',
    lynxCloudHost: 'mq.lynx.dev',
    lynxCloudPort: 443,

    enableSpatialAdjust: true,
    tempSpatialAdjustOrganizationId: 26,    // Austin

    enableAnalytics: true,
    mixpanelKey: 'f185c9dc08b6f834b29bb17bc01b3202',

    notificationPollingIntervalInMinutes: 1,

    feedbackSubject: 'Feedback on IntelliDash - Dev',
    feedbackRecipients: ['markw@1218team.com', 'alex.rodriguez@toro.com'],

    showChatWidget: true
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
